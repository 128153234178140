<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6" class="d-flex justify-start align-center">
        <new-app-bar-route
          :title="$trans('services')"
          :subtitle="$trans('section_admin_panel_offer')"
        />
      </v-col>
      <v-col
        v-if="servicesExist"
        cols="12"
        md="6"
        class="d-flex justify-start justify-md-end align-center flex-wrap"
      >
        <v-btn
          depressed
          outlined
          color="secondary"
          class="ma-1"
          @click="handleAddNewService"
        >
          <v-icon left> $plus-circle</v-icon>
          <span>{{ $trans("add_service") }}</span>
        </v-btn>
        <v-btn
          depressed
          outlined
          color="secondary"
          class="ma-1"
          @click="handleAddNewCategory"
        >
          <v-icon left> $plus-circle</v-icon>
          <span>{{ $trans("add_category") }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NewAppBarRoute from "@/views/dashboard/components/AppBar/AppBarComponents/NewAppBarRoute.vue";
import statsActions from "@/calendesk/mixins/statsActions";
import categoryServiceActions from "@/calendesk/mixins/categoryServiceActions";

export default {
  components: {
    NewAppBarRoute,
  },
  mixins: [statsActions, categoryServiceActions],
};
</script>
