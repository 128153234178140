<template>
  <div v-if="getStatsLoaded" class="full-width">
    <app-bar-services v-if="tab === availableTabs.offer.services" />
    <app-bar-subscriptions v-if="tab === availableTabs.offer.subscriptions" />
    <app-bar-products v-if="tab === availableTabs.offer.products" />
  </div>
</template>

<script>
import sharedAppBarActions from "@/calendesk/mixins/sharedAppBarActions";
import sharedTabsActions from "@/calendesk/mixins/sharedTabsActions";
import AppBarProducts from "@/views/dashboard/pages/Offer/AppBar/AppBarProducts.vue";
import AppBarSubscriptions from "@/views/dashboard/pages/Offer/AppBar/AppBarSubscriptions.vue";
import AppBarServices from "@/views/dashboard/pages/Offer/AppBar/AppBarServices.vue";

export default {
  components: {
    AppBarServices,
    AppBarSubscriptions,
    AppBarProducts,
  },
  mixins: [sharedAppBarActions, sharedTabsActions],
};
</script>
